<template>
  <div class="open-app">
    <!-- <div class="open-app-title">分享页面</div> -->
    <div class="open-app-body">
      <div v-if="imagePath">
        <el-image
          :style="{ width: '100%', maxHeight: '200px' }"
          :src="imagePath"
          fit="v"
          :lazy="true"
        ></el-image>
      </div>
      <el-button-group class="open-app-btngroup">
        <el-button :type="tabIndex === 0 ? 'primary' : 'default'" @click="setTabIndex(0)">
          设备信息
        </el-button>
        <el-button :type="tabIndex === 1 ? 'primary' : 'default'" @click="setTabIndex(1)">
          故障记录
        </el-button>
        <el-button
          v-if="monitorStatus === 'Y'"
          :type="tabIndex === 2 ? 'primary' : 'default'"
          @click="setTabIndex(2)"
        >
          运行监视
        </el-button>
      </el-button-group>
      <div class="open-app-content">
        <el-scrollbar v-show="tabIndex === 0">
          <div class="open-app-content-box">
            <div v-for="item in equipmentInfo.info" :key="item">
              <div>{{ item.label }}</div>
              <div>{{ item.value }}</div>
            </div>
          </div>
          <div class="open-app-content-box">
            <div v-for="item in equipmentInfo.geogInfo" :key="item">
              <div>{{ item.label }}</div>
              <div>{{ item.value }}</div>
            </div>
          </div>
          <div class="open-app-content-box">
            <div v-for="item in equipmentInfo.statusInfo" :key="item">
              <div>{{ item.label }}</div>
              <div>{{ item.value }}</div>
            </div>
          </div>
        </el-scrollbar>
        <el-scrollbar v-show="tabIndex === 1">
          <div>
            <div class="open-app-content-item" v-for="item in equipmentRecord" :key="item">
              <div>{{ item.title }}</div>
              <div
                class="open-app-content-item-showStatusText"
                :class="{
                  'open-app-content-item-showStatusTextColor': item.errorStatus === '3'
                }"
              >
                {{ item.errorStatus === '3' ? '已完成' : '待处理' }}
              </div>
              <div class="open-app-content-item-box">
                <div>故障等级</div>
                <div>{{ item.severity }}</div>
              </div>
              <div class="open-app-content-item-box">
                <div>{{ item.reportUser }}</div>
                <div>{{ item.submitTime }}</div>
              </div>
            </div>
          </div>
        </el-scrollbar>
        <el-scrollbar v-show="tabIndex === 2">
          <div class="monitor-box">
            <div
              v-for="item in tabberData.data"
              :key="item.id"
              class="monitor-item"
              @click="openHandle(item)"
            >
              <div class="monitor-header">
                <span class="item-title">{{ item.propertyName }}</span>
                <img :src="require('@/assets/image/icon-monitor.png')" class="icon-monitor" />
              </div>
              <div class="monitor-content">{{ item.propertyText }}{{ item.unit }}</div>
              <div class="monitor-bottom">更新时间：{{ item.time }}</div>
            </div>
            <div v-if="tabberData.data.length === 0" class="emptry-box">暂无数据</div>
          </div>
        </el-scrollbar>
      </div>
      <!-- <wx-open-launch-weapp
        class="openweapp"
        id="launch-btn"
        username="gh_dbbf66f8ae0b"
        path="pages/fault/index"
      >
        <div v-is="'script'" type="text/wxtag-template">
          <button class="btn">打开小程序</button>
        </div>
      </wx-open-launch-weapp> -->
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
// import wx from 'weixin-js-sdk'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { BASEURL, FILEPREVIEWURL } from '@API'

export default {
  name: 'OpenApp',
  setup() {
    const router = useRouter()
    const { dispatch } = useStore()
    const route = useRoute()

    const { deviceId } = route.query

    const tabIndex = ref(0)
    const setTabIndex = index => {
      tabIndex.value = index
    }
    const imagePath = ref('')
    const equipmentInfoData = ref({})
    const monitorStatus = ref('')
    const equipmentInfo = computed(() => {
      return {
        info: [
          {
            label: '设备名称',
            value: equipmentInfoData.value.deviceName || ''
          },
          {
            label: '资产编号',
            value: equipmentInfoData.value.propertyCode || ''
          },
          {
            label: '所属项目',
            value: equipmentInfoData.value.projectName || ''
          },
          {
            label: '资产类别',
            value: equipmentInfoData.value.propertyType || ''
          },
          {
            label: '设备类型',
            value: equipmentInfoData.value.deviceType || ''
          }
        ],
        geogInfo: [
          {
            label: '所属一级单位',
            value: equipmentInfoData.value.firstUnit || ''
          },
          {
            label: '所属二级单位',
            value: equipmentInfoData.value.secondUnit || ''
          },
          {
            label: '安装位置',
            value: equipmentInfoData.value.address || ''
          },
          {
            label: '经度',
            value: equipmentInfoData.value.longitude || ''
          },
          {
            label: '纬度',
            value: equipmentInfoData.value.latitude || ''
          },
          {
            label: '维护单位',
            value: equipmentInfoData.value.companyName || ''
          }
        ],
        statusInfo: [
          {
            label: '规格/型号',
            value: equipmentInfoData.value.model || ''
          },
          {
            label: '资产状态',
            value: equipmentInfoData.value.propertyStatus || ''
          },
          {
            label: '数量',
            value: equipmentInfoData.value.deviceCount || ''
          },
          {
            label: '质保期(年)',
            value: equipmentInfoData.value.warranty || ''
          },
          {
            label: '维修记录',
            value:
              equipmentInfoData.value.errorCount - equipmentInfoData.value.undoErrorCount > 0
                ? equipmentInfoData.value.errorCount - equipmentInfoData.value.undoErrorCount
                : 0
          },
          {
            label: '生产日期',
            value: formetDataYear(equipmentInfoData.value.productDate)
          },
          {
            label: '生产厂商',
            value: equipmentInfoData.value.manufacturer || ''
          },
          {
            label: '描述信息',
            value: equipmentInfoData.value.remark || ''
          }
        ]
      }
    })
    const equipmentRecord = ref([
      // {
      //   title: '这里是故障标题（例如服务器更换）',
      //   severity: '严重',
      //   submitTime: '2021-10-20',
      //   reportUser: 'wq',
      //   showStatusText: '待处理'
      // },
      // {
      //   title: '这里是故障标题（例如服务器更换）',
      //   severity: '严重',
      //   submitTime: '2021-10-20',
      //   reportUser: 'wq',
      //   showStatusText: '已办结'
      // }
    ])
    const openAppFn = row => {
      // wx.miniProgramuni.navigateTo({ url: '/pages/fault/index' })
    }
    const tabberData = reactive({
      data: []
    })
    const getLastRtuList = () => {
      dispatch('fetchLastRtuAttrList', { deviceId }).then(data => {
        if (data.code === 200) {
          tabberData.data = data.data.attrList || []
        }
      })
    }

    const formetDataYear = date => {
      if (date) {
        let array = date.split(' ')
        return (array && array[0]) || ''
      }
      return ''
    }

    const openHandle = row => {
      router.push({
        path: '/openAppHistory',
        query: { deviceId: row.deviceId, propertyCode: row.propertyCode }
      })
    }

    onMounted(() => {
      // console.log(equipmentInfo.value)
      if (deviceId) {
        dispatch('fetchGetDeviceInfoShare', deviceId).then(data => {
          if (data && data.code === 200) {
            equipmentInfoData.value = data.data || {}
            if (equipmentInfoData.value.imagePath) {
              imagePath.value = `${BASEURL}${FILEPREVIEWURL}/${equipmentInfoData.value.imagePath}`
            }
            monitorStatus.value = equipmentInfoData.value.monitorStatus
            if (monitorStatus.value === 'Y') {
              getLastRtuList()
            }
          }
        })
        dispatch('fetchGetDeviceErrorRecordsShare', { deviceId }).then(data => {
          if (data && data.code === 200) {
            equipmentRecord.value = data.data
          }
        })
      }
      //       dispatch('fetchWeixinAuthorizeInfo', {
      //         // url: location.href
      //       }).then(data => {
      //         const { appId, nonceStr, signature, timestamp } = data.data
      //         wx.config({
      //           debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      //           appId, // 必填，公众号的唯一标识，填自己的！
      //           timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
      //           nonceStr, // 必填，生成签名的随机串
      //           signature, // 必填，签名，见附录1
      //           jsApiList: ['updateTimelineShareData'],
      //           openTagList: ['wx-open-launch-weapp'] // 跳转小程序时必填
      //         })
      //         wx.ready(res => {
      // console.log(res, '2')
      //         })
      //         wx.error(res => {
      // console.log(res, '1')
      //         })
      //       })
    })
    return {
      equipmentInfo,
      equipmentRecord,
      imagePath,
      tabIndex,
      setTabIndex,
      openAppFn,
      getLastRtuList,
      tabberData,
      openHandle,
      monitorStatus,
      formetDataYear
    }
  }
}
</script>

<style lang="scss">
%btn {
  background: linear-gradient(to left, #3b7fff, #6c9fff) !important;
  // border-radius: 10px !important;
  height: 48px;
}
.open-app {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to left, #3b7fff, #6c9fff);
  display: flex;
  flex-direction: column;
  &-title {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    text-align: center;
    color: #fff;
    padding: 5px;
  }
  &-info {
    text-align: center;
    padding: 20px {
      top: 60px;
    }
  }
  &-body {
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin: 15px;
    flex: 1;
    border-radius: 20px;
    height: calc(100% - 120px);
    .el-button {
      margin: 10px 0;
    }
  }
  &-content {
    // margin: 30px 0 20px;
    // padding-bottom: 40px;

    flex: 1;
    overflow: hidden;
    padding: 10px 0;
    &-box {
      padding: 10px 0;
      border-bottom: 2px dashed #d8d8d8;
      > div {
        display: flex;
        justify-content: space-between;
        // font-weight: bold;
        min-height: 25px;
        line-height: 25px;
        font-size: 14px;
        > div:first-child {
          flex: 1;
        }
        > div:last-child {
          font-weight: normal;
          color: #666;
          flex: 2;
          display: flex;
          justify-content: flex-end;
          max-width: 95%;
          word-break: break-all;
        }
      }
    }
    &-item {
      position: relative;
      border-bottom: 2px dashed #d8d8d8;
      padding: 10px 0;
      > div {
        min-height: 25px;
        line-height: 25px;
        font-size: 14px;
      }
      &-box {
        display: flex;
        justify-content: space-between;
      }
      &-showStatusText {
        // position: absolute;
        text-align: right;
        color: #ff627c;
      }
      &-showStatusTextColor {
        color: #93dfc3;
      }
    }
  }
  &-btn {
    @extend %btn;
  }
  &-amapBtn {
    @extend %btn;
    background: #fff !important;
    color: #3b7fff !important;
    border: 1px solid #3b7fff !important;
  }
  &-btngroup {
    display: flex;
    .el-button {
      flex: 1;
    }
  }
  .monitor-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .monitor-item {
    width: 48%;
    border: 1px solid #ecedf1;
    box-sizing: border-box;
    margin-right: 4%;
    margin-bottom: 16px;
    box-shadow: 10px 10px 10px #f6f6f6;
    border-radius: 4px;
  }
  .monitor-item:nth-child(2n) {
    margin-right: 0;
  }
  .monitor-header {
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ededed;
    padding: 0 10px;
  }
  .item-title {
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
  .monitor-content {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #52c432;
    cursor: pointer;
  }
  .monitor-bottom {
    height: 48px;
    text-align: center;
    font-size: 12px;
  }
  .icon-monitor {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .emptry-box {
    margin-top: 150px;
    text-align: center;
    color: #ccc;
    width: 100%;
  }
}
</style>
